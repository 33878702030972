import axios from "axios";

const baseURL =
  process.env.NODE_ENV === "production" ? "https://admin.huangheyanxue.com.cn/" : "/apis"; //线上正式地址

// const baseURL =
//   process.env.NODE_ENV === "production" ? "https://hhyx.zzbyun.cn/" : "/apis"; //这是站站宝测试域名

// const baseURL =
  // process.env.NODE_ENV === "production" ? "http://huanghe.ifokey.com/" : "/apis"; //本地域名地址

// const baseURL = process.env.NODE_ENV === 'production' ? '/api' : '/api'
export const httpInstance = axios.create({
  timeout: 30000,
  withCredentials: false,
  baseURL,
  validateStatus(status) {
    return status >= 200 && status <= 509;
  },
});

httpInstance.interceptors.response.use(
  (res) => {
    const status = res.status || 200;
    if (status !== 200) {
      console.log(res);
      return Promise.reject(res);
    }
    return res.data;
  },
  (error) => {
    return Promise.reject(error);
  }
);
